@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}

@media (min-width: 992px) {
  .chart-container .row {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 991px) {
  .chart-container .row {
    flex-direction: column;
  }
}
